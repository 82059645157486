<template>
  <div>
    <KTCodePreview v-bind:title="'Lịch sử xuất excel'">
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col md="3">
                <treeselect
                  v-model="apiParams.employeeId"
                  :options="employeeOptions"
                  :multiple="true"
                  :flatten-search-results="true"
                  :match-keys="['name', 'nameNoneSign']"
                  placeholder="Hiển thị theo nhân viên "
                  noResultsText="Không tìm thấy nhân viên"
                  :limit="6"
                />
              </b-col>
              <b-col md="3">
                <treeselect
                  v-model="apiParams.moduleType"
                  :options="listModule"
                  :multiple="true"
                  :flatten-search-results="true"
                  placeholder="Hiển thị theo module"
                  noResultsText="Không tìm thấy module"
                />
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                  <span class="mr-1 ml-1"></span>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </div>
              </b-col>
              <b-col md="2">
                <b-form-select
                  size="sm"
                  required
                  class="select-style"
                  placeholder="Hiển thị"
                  v-model="apiParams.limit"
                  :options="limitOptions"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  filter
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col
                md="1"
                style="padding-left: 0"
              >
                <b-overlay
                  :show="isBusy"
                  opacity="0.6"
                >
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="fetchReportHistoryChart()"
                    class="ml-3"
                  >Cập nhật</b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <div id="chart">
          <apexchart
            type="bar"
            ref="chart"
            :height="400"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <b-row class="d-flex justify-content-center">
          <span class="font-weight-bold">
            {{ chartTitle }}
          </span>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.autosugges-container {
  margin: 0;
  padding: 0;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { makeToastFaile, xoa_dau } from '@/utils/common';
import { MODULE_TYPE } from '@/utils/enum';
import {
  DP_CONFIG,
  getSubtractDate,
  formatSpecificDate,
  DATE_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from '@/utils/date';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

export default {
  components: {
    KTCodePreview,
    Treeselect,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      apiParams: {
        moduleType: null,
        toDate: null,
        fromDate: getSubtractDate(7),
        employeeId: null,
        limit: 3,
      },
      limitOptions: [
        { id: null, name: 'Hiển thị' },
        { id: 1, name: 'Top 1' },
        { id: 3, name: 'Top 3' },
        { id: 5, name: 'Top 5' },
        { id: 10, name: 'Top 10' },
      ],
      listModule: [
        { id: MODULE_TYPE.CUSTOMER, label: 'Khách hàng' },
        { id: MODULE_TYPE.ORDER, label: 'Đơn hàng' },
        { id: MODULE_TYPE.BILL, label: 'Bán hàng' },
        { id: MODULE_TYPE.PRODUCT, label: 'Sản phẩm' },
        { id: MODULE_TYPE.PRODUCT_IMEI, label: 'Sản phẩm IMEI' },
        { id: MODULE_TYPE.TRADE_IN, label: 'Thu cũ' },
        { id: MODULE_TYPE.PRE_ORDER, label: 'Đơn đặt trước' },
      ],
      dpConfigs: DP_CONFIG,
      isBusy: false,

      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          width: 'auto',
          scrollable: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            pointWidth: 20,
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'top',
        },
      },
      chartTitle: '',
      employeeOptions: [],
      LIMIT_RANGE_DATE: 31
    };
  },
  created() {
    this.fetchReportHistoryChart();
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Lịch sử xuất excel',
        route: 'report-excel',
      },
    ]);
  },
  methods: {
    async fetchReportHistoryChart() {
      try {
        const currentDate = moment().format(DATE_FORMAT);
        const fromDate = this.apiParams.fromDate || '';
        const toDate = this.apiParams.toDate || currentDate;
        if (this.isBusy || this.isInValidRangDate(fromDate, toDate)) return;
        this.isBusy = true;
        const params = {
          ...this.apiParams,
          fromDate: formatSpecificDate(
            fromDate,
            DATE_FORMAT,
            YEAR_MONTH_DAY_FORMAT,
          ),
          toDate: formatSpecificDate(
            toDate,
            DATE_FORMAT,
            YEAR_MONTH_DAY_FORMAT,
          ),
        };
        const response = (
          await ApiService.query('report-excel/chart', { params })
        ).data.data;
        this.series = response.series;
        const topTitle = this.apiParams.limit
          ? `Top ${this.apiParams.limit}`
          : '';
        this.chartTitle = `Biểu đồ thống kê ${topTitle} user xuất excel từ ${fromDate} - ${toDate}`;
        this.chartOptions.xaxis.categories = response.categories;
        this.employeeOptions = response.series.map((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return {
            nameNoneSign,
            name: item.name,
            label: item.name,
            id: item.employeeId,
          };
        });
        this.$refs.chart.refresh();
      } catch (err) {
        const message = err.response?.data?.message || 'Network Error';
        return makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    isInValidRangDate(fromDate, toDate) {
      const startMoment = moment(fromDate, DATE_FORMAT);
      const endMoment = moment(toDate, DATE_FORMAT);
      const diffDate = endMoment.diff(startMoment, 'days');
      if (!fromDate || diffDate > this.LIMIT_RANGE_DATE) {
        makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 1 tháng!');
        return true;
      }
      return false;
    },
  },
};
</script>
